import { useDashboardUIActions, useDashboardUIContext } from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { isBuildingTrial } from "@utils/checkPermiossion";
import { formatNumberToCurrency } from "@utils/formatNumber";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useMemo } from "react";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FinancialBarChart = ({ graphSet = 'graphB' }) => {

  const { activeProject: { budget, paidValues = [], disbursements = [], toPayValues = [], receivedBills = [], toReceiveBills = [], takenFrom = [], takenTo = [], followUps = [], trend = [], accumulatedDisbursement = [], accumulatedPaid = [] } } = useProjectContext();
  const { barChartDate } = useDashboardUIContext()
  const { setBarChartDate, setIsLoading } = useDashboardUIActions()
  const { isLoading } = useDashboardUIContext()
  const { selectXlsxData } = useProjectActions()
  const { filterDates } = useProjectContext()

  const receivedDates = Array.from(new Set(receivedBills.map(i => i.date)))
  const toReceiveDates = Array.from(new Set(toReceiveBills.map(i => i.date)))
  const scheduled = followUps.map(i => {
    return { value: i.scheduled * budget.value, date: i.date }
  })


  const allDates = Array.from(new Set([...receivedDates, ...toReceiveDates]))

  let lastReceived = 0;
  let lastToReceive = 0;

  const allBillsRaw = allDates.map(i => {
    const receivedIdx = receivedBills.findIndex(j => j.date === i)
    const toReceivedIdx = toReceiveBills.findIndex(j => j.date === i)

    let currentReceived = receivedBills[receivedIdx]?.accumulated
    if (!currentReceived) {
      currentReceived = lastReceived
    }
    lastReceived = currentReceived

    let currentToReceive = toReceiveBills[toReceivedIdx]?.accumulated
    if (!currentToReceive) {
      currentToReceive = lastToReceive
    }
    lastToReceive = currentToReceive

    return { date: i, accumulated: (lastReceived ?? 0) + (lastToReceive ?? 0), value: (receivedBills[receivedIdx]?.value ?? 0) + (toReceiveBills[toReceivedIdx]?.value ?? 0) }
  })

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [barChartDate])

  const [paidList, disbursementList, toPayList, allBillsList, takenFromList, takenToList, dates] = useMemo(() => {

    const paidDates = paidValues.reduce((accumulator, item) => {
      accumulator.push(item.date)
      return accumulator;
    }, [])

    const disbursementDates = disbursements.reduce((accumulator, item) => {
      accumulator.push(item.date)
      return accumulator
    }, [])

    const toPayDates = toPayValues.reduce((accumulator, item) => {
      accumulator.push(item.date)
      return accumulator
    }, [])

    const allBillsDates = allBillsRaw.reduce((accumulator, item) => {
      accumulator.push(item.date)
      return accumulator
    }, [])


    const takenFromDates = takenFrom.byMonth.reduce((accumulator, item) => {
      accumulator.push(item.from)
      return accumulator
    }, [])

    const takenToDates = takenTo.byMonth.reduce((accumulator, item) => {
      accumulator.push(item.to)
      return accumulator
    }, [])

    const dates = [...paidDates, ...disbursementDates, ...toPayDates, ...allBillsDates, ...takenFromDates, ...takenToDates]

      .filter((item, index, self) => index === self.findIndex((indexedItem) => indexedItem === item))
      .sort((current, next) => {
        const [currentMonth, currentYear] = current.split("/")
        const [nextMonth, nextYear] = next.split("/")
        if (currentYear !== nextYear) {
          return Number(currentYear) - Number(nextYear);
        } else {
          return Number(currentMonth) - Number(nextMonth);
        }
      })

    const { paidMap, disbursementMap, toPayMap, allBillsMap, takenFromMap, takenToMap } = dates.reduce((acc, item) => {
      acc.paidMap.set(item, paidValues.find(paidItem => paidItem.date === item)?.value ?? 0)
      acc.disbursementMap.set(item, disbursements.find(disbursementItem => disbursementItem.date === item)?.value ?? 0)
      acc.toPayMap.set(item, toPayValues.find(toPayItem => toPayItem.date === item)?.value ?? 0)
      acc.allBillsMap.set(item, allBillsRaw.find(billItem => billItem.date === item)?.value ?? 0)
      acc.takenFromMap.set(item, takenFrom.byMonth.find(takenItem => takenItem.from === item)?.value ?? 0)
      acc.takenToMap.set(item, takenTo.byMonth.find(takenItem => takenItem.to === item)?.value ?? 0)
      return acc;
    }, { paidMap: new Map(), disbursementMap: new Map(), toPayMap: new Map(), allBillsMap: new Map(), takenFromMap: new Map(), takenToMap: new Map() })

    return [Array.from(paidMap.values()), Array.from(disbursementMap.values()), Array.from(toPayMap.values()), Array.from(allBillsMap.values()), Array.from(takenFromMap.values()), Array.from(takenToMap.values()), dates]
  }, [paidValues, disbursements, toPayValues, allBillsRaw, takenFrom, takenTo])


  const onBarClick = (event, itemsClicked) => {
    if (!event.chart.tooltip.dataPoints) return
    if (itemsClicked) {
      const selectedDate = event.chart.tooltip.dataPoints[0]?.label
      if (selectedDate == barChartDate) {
        setBarChartDate();
      } else {
        setBarChartDate(selectedDate)
      }
    } else {
      setBarChartDate();
    }
  }

  const scheduledList = scheduled.map(i => i.value)
  const accumulatedDisbursementList = accumulatedDisbursement.map(i => i.value)
  const accumulatedPaidList = accumulatedPaid.map(i => i.value)
  const allBillsRawList = allBillsRaw.map(i => i.value)

  const graphs = {
    graphA: [
      {
        label: "Receber/Recebidas",
        borderColor: "#1f1f1f",
        backgroundColor: "#1f1f1f",
        data: allBillsList,
        type: 'line'
      },
      {
        label: "Desembolso",
        borderColor: "#6852F2",
        backgroundColor: "#6852F2",
        data: disbursementList,
        stack: 'disbursement'
      },
      {
        label: "Pago",
        borderColor: "#3acff7",
        backgroundColor: "#3acff7",
        data: paidList,
        stack: 'paid'
      },
      {
        label: "A pagar",
        borderColor: "#fc9403",
        backgroundColor: "#fc9403",
        data: toPayList,
        stack: 'paid'
      },
      {
        label: "Adiantado de",
        borderColor: "#3CB371",
        backgroundColor: "#3CB371",
        data: takenToList,
        stack: 'to'
      },
      {
        label: "Adiantado para",
        borderColor: "#FF6F61",
        backgroundColor: "#FF6F61",
        data: takenFromList,
        stack: 'from'
      },
    ],
    graphB: [
      {
        label: 'Previsto',
        borderColor: "#3acff7",
        backgroundColor: "#3acff7",
        type: 'line',
        data: scheduledList,
        stack: 'predicted'
      },
      {
        label: 'Desembolso Acumulado',
        borderColor: "#6852F2",
        backgroundColor: "#6852F2",
        type: 'line',
        data: accumulatedDisbursementList,
        stack: 'disbursement'
      },
      {
        label: 'Pago Acumulado',
        borderColor: "#fc9403",
        backgroundColor: "#fc9403",
        type: 'line',
        data: accumulatedPaidList,
        stack: 'paid'
      },
      {
        label: "Receber/Recebidas",
        borderColor: "#1f1f1f",
        backgroundColor: "#1f1f1f",
        data: allBillsRaw.map(i => i.accumulated),
        type: 'line'
      },
    ]
  }

  const graphOptions = {
    scales: {
      x: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: 'Mês/Ano',
          font: {
            weight: 'bold'
          }
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Valor',
          font: {
            weight: 'bold'
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: onBarClick,
    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: false,
        text: "Projeção Financeira",
        align: "start",
        color: "black",
        font: {
          size: "18px"
        }
      },
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const label = tooltipItem.label
            if (tooltipItem.dataset.label === "Receber/Recebidas") {
              const toGet = graphSet === 'graphA' ? 'value' : 'accumulated'

              const toReceive = toReceiveBills.filter(i => i.date === label)[0]
              const toReceiveValue = toReceive !== undefined ? toReceive[toGet] : 0

              const received = receivedBills.filter(i => i.date === label)[0]
              const receivedValue = received !== undefined ? received[toGet] : 0

              return [`Total: ${formatNumberToCurrency(allBillsRaw[index][toGet])}`, `Recebidas: ${formatNumberToCurrency(receivedValue)}`, `A receber: ${formatNumberToCurrency(toReceiveValue)}`];
            }
            if (tooltipItem.dataset.label === 'Adiantado para') {
              const list = takenTo.byItem.filter(i => {
                return i.data.some(j => j.to === tooltipItem.label)
              })
              let response = []
              list.forEach((i) => i.data.map(j => response.push(`${i.name}: ${formatNumberToCurrency(j.value ?? 0)}, de ${j.from}`)))
              return [`Total: ${formatNumberToCurrency(list.reduce((a, b) => a + b.data.reduce((c, d) => c + d.value ?? 0, 0), 0))}`, ...response]
            }
            if (tooltipItem.dataset.label === 'Adiantado de') {
              const list = takenFrom.byItem.filter(i => {
                return i.data.some(j => j.from === tooltipItem.label)
              })
              let response = []
              list.forEach((i) => i.data.map(j => response.push(`${i.name}: ${formatNumberToCurrency(j.value ?? 0)}, para ${j.to}`)))
              return [`Total: ${formatNumberToCurrency(list.reduce((a, b) => a + b.data.reduce((c, d) => c + d.value ?? 0, 0), 0))}`, ...response]
            }
            return `${dataset.label}: ${tooltipItem.formattedValue}`;
          }
        }
      }
    }
  }



  useEffect(() => {

    let fields, resources;

    if (graphSet === 'graphA') {
      fields = [
        { label: 'Data', key: 'date' },
        { label: 'Receber/Recebidas', key: 'allBills' },
        { label: 'Desembolso', key: 'disbursement' },
        { label: 'Pago', key: 'paid' },
        { label: 'A Pagar', key: 'toPay' },
        { label: 'Adiantado de', key: 'takenTo' },
        { label: 'Adiantado para', key: 'takenFrom' },
      ]
      resources = dates.map((date, index) => ({
        date,
        allBills: allBillsList[index],
        disbursement: disbursementList[index],
        paid: paidList[index],
        toPay: toPayList[index],
        takenTo: takenToList[index],
        takenFrom: takenFromList[index],
      }));
    } else {
      fields = [
        { label: 'Data', key: 'date' },
        { label: 'Previsto', key: 'scheduled' },
        { label: 'Desembolso Acumulado', key: 'accumulatedDisbursement' },
        { label: 'Pago Acumulado', key: 'accumulatedPaid' },
        { label: 'Receber/Recebidas', key: 'allBillsRaw' }
      ]
      resources = dates.map((date, index) => ({
        date,
        scheduled: scheduledList[index],
        accumulatedDisbursement: accumulatedDisbursementList[index],
        accumulatedPaid: accumulatedPaidList[index],
        allBillsRaw: allBillsRaw.map(i => i.accumulated)[index],
      }));
    }

    selectXlsxData({ fields, resources })
  }, [isLoading])


  if (graphSet === 'graphA') {
    return <Bar options={graphOptions}
      data={{
        labels: dates,
        datasets: !isBuildingTrial() ? graphs.graphA : [
          {
            label: "Desembolso",
            borderColor: "#6852F2",
            backgroundColor: "#6852F2",
            data: disbursementList,
            stack: 'disbursement'
          },

        ]
      }} />
  } else {
    return <Line options={graphOptions}
      data={{
        labels: dates,
        datasets: !isBuildingTrial() ? graphs.graphB : [
          {
            label: "Desembolso",
            borderColor: "#6852F2",
            backgroundColor: "#6852F2",
            data: disbursementList,
            stack: 'disbursement'
          },

        ]
      }} />

  }
}

export default FinancialBarChart;