import { permissionRoles } from "@utils/permissions";

const UserRow = ({ user, edit, reset }) => {
  const { name, roles, email } = user;
  return <div className="user-row">
    <span>{name}</span>
    <span>{email}</span>
    <span>{permissionRoles.map(i => {
      if (roles.includes(i.value)) {
        return `${i.name}, `
      }
    })}</span>
    <button onClick={edit}>Editar</button>
    <button onClick={reset}>Resetar senha</button>
  </div>
}

export default UserRow;