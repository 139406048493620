const currentDate = new Date()
export default {
  projects: [],
  companies: [],
  filterDates: {
    beginAt: new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() - 3, 1)),
    endAt: new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 3, 1)),
  },
  selectedDetail: {},
  curveID: 0,
  cubBased: false,
  xlsxData: {}
};
