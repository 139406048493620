import Cookies from "universal-cookie";
import {
  useDashboardUIActions,
} from "@contexts/dashboardUI";

const cookies = new Cookies();


export default (state, dispatch) => {
  const getBuildings = async (name = '') => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getBuildings?name=${name}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const selectBuilding = (building) => {
    dispatch({
      type: "SELECT_PROJECT",
      data: building
    })
  }

  const updateBuilding = async (building) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateBuilding`, {
      method: "POST",
      body: JSON.stringify(building)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getParams = async (type) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getParams?group=${type}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  const updateParams = async (params) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateParam`, {
      method: "POST",
      body: JSON.stringify(params)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getUnitValues = async (paramId) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getUnitValues?group=${paramId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  const updateUnitValues = async (unitValue) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateUnitValue`, {
      method: "POST",
      body: JSON.stringify(unitValue)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getBuildingItems = async (type, buildingId) => {
    const response = await fetch(`${process.env.SERVER_URL}/costs/getBuildingItems?type=${type}&buildingId=${buildingId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const updateBuildingItem = async (item) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateBuildingItem`, {
      method: "POST",
      body: JSON.stringify(item)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getMeasurementUnits = async (type) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getMeasurementUnits`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  const updateMeasurementUnits = async (item) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateMeasurementUnits`, {
      method: "POST",
      body: JSON.stringify(item)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getIndexers = async () => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getCost`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  const updateIndexer = async (item) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateCost`, {
      method: "POST",
      body: JSON.stringify(item)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getBuildingTotals = async (buildingId) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getBuildingTotals?buildingId=${buildingId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const getIndexes = async () => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getIndexes`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  const updateIndex = async (item) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/updateIndex`, {
      method: "POST",
      body: JSON.stringify({ name: item })
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const updateCubFilter = (item) => {
    dispatch({
      type: "SELECT_CUB_FILTER",
      data: item
    })
  }

  const updateAllCubs = (item) => {
    dispatch({
      type: "UPDATE_ALL_CUBS",
      data: item
    })
  }

  const getCredors = async (name = '', page = 1, type = 'loose') => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/costs/getCredors?name=${name}&page=${page}&type=${type}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  return {
    getBuildings,
    updateBuilding,
    getParams,
    updateParams,
    getBuildingItems,
    updateBuildingItem,
    getMeasurementUnits,
    updateMeasurementUnits,
    selectBuilding,
    getIndexers,
    updateIndexer,
    getBuildingTotals,
    getIndexes,
    updateIndex,
    updateCubFilter,
    updateAllCubs,
    getCredors,
    getUnitValues,
    updateUnitValues
  };
};
