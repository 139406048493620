import * as XLSX from 'xlsx';

export const downloadCsv = (e, fields, resources) => {
  e.preventDefault();

  // Cria uma nova planilha
  const worksheet = XLSX.utils.json_to_sheet(
    resources.map(resource => {
      const row = {};
      fields.forEach(field => {
        row[field.label] = resource[field.key] ?? '';
      });
      return row;
    })
  );

  // Cria um novo workbook e adiciona a planilha
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Converte o workbook para um arquivo binário
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

  // Cria um Blob a partir do arquivo binário
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

  // Cria um link para download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', new Date().toLocaleString().replace(/[/,:]/g, '-') + '.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Função auxiliar para converter string para array buffer
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}