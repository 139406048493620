import Cookies from "universal-cookie";
import {
  useDashboardUIActions,
} from "@contexts/dashboardUI";

const cookies = new Cookies();


export default (state, dispatch) => {
  const list = async (activeId) => {
    const user = cookies.get("auth");
    // if (!user) return;

    const response = await fetch(`${process.env.SERVER_URL}/project?user_id=${location.host === 'localhost:8082' ? '65af3fbf4d779247054d12c4' : user._id}`) //HARDCODED
    // const response = await fetch(`${process.env.SERVER_URL}/project?user_id=65af3fbf4d779247054d12c4`)
    if (response.ok) {
      const { projects, companies } = await response.json();
      if (projects.length > 0) {
        if (projects.find(({ _id }) => _id === activeId)) {
          await selectProject(activeId);
        } else {
          await selectProject(projects[0]._id)
        }
      }

      dispatch({
        type: "FOUND_PROJECTS",
        data: { projects, companies }
      })
    } else {
      dispatch({
        type: "FOUND_PROJECTS",
        data: []
      })
    }
  }

  const selectProject = async (_id) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const { filterDates: { beginAt, endAt } } = state
    const response = await fetch(`${process.env.SERVER_URL}/project/${_id}/detail?begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`)
    if (response.ok) {
      const { project } = await response.json();
      dispatch({
        type: "SELECT_PROJECT",
        data: project
      })
    }
    setIsLoading(false)
  }

  const updateProjectLive = (project) => {
    dispatch({
      type: "SELECT_PROJECT",
      data: project
    })
  }

  const changeDates = async (_id, begin, end) => {
    dispatch({
      type: "CHANGE_FILTER_DATES",
      data: {
        begin, end
      }
    })
    getFollowUps(_id, begin, end)
  }

  const updateCurveID = async (id) => {
    dispatch({
      type: 'UPDATE_CURVE_ID',
      data: id
    })
  }

  const updateCubBased = async (state) => {
    dispatch({
      type: 'UPDATE_CUB_BASED',
      data: state
    })
  }


  const getFollowUps = async (_id, begin, end) => {
    const { setIsLoading } = useDashboardUIActions();

    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/project/${_id}/detail/followups?begin_at=${begin.toISOString()}&end_at=${end.toISOString()}`)
    if (response.ok) {
      const { followUps, paidValues, disbursements, toPayValues, toReceiveBills, receivedBills, takenTo, takenFrom, accumulatedDisbursement, accumulatedPaid } = await response.json();
      dispatch({
        type: "UPDATE_FOLLOW_UPS",
        data: {
          begin,
          end,
          followUps,
          paidValues,
          disbursements,
          toPayValues,
          toReceiveBills,
          receivedBills,
          takenFrom,
          takenTo,
          accumulatedDisbursement,
          accumulatedPaid
        }
      })
    }
    setIsLoading(false)
  }

  const loadUnits = async (dateString) => {
    let beginAt;
    let endAt;
    if (dateString) {
      const [month, year] = dateString.split("/")
      const date = new Date(year, month - 1);
      beginAt = date;
      endAt = date;
    } else {
      beginAt = state.filterDates.beginAt
      endAt = state.filterDates.endAt
    }
    const response = await fetch(`${process.env.SERVER_URL}/project/${state.activeProject._id}/units?begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`)
    if (response.ok) {
      const body = await response.json();
      const { units } = body;
      dispatch({
        type: "LOADED_UNITS",
        data: { units }
      })
    }
  }

  const loadCells = async (wbs_unit, dateString) => {
    let beginAt;
    let endAt;
    if (dateString) {
      const [month, year] = dateString.split("/")
      const date = new Date(year, month - 1);
      beginAt = date;
      endAt = date;
    } else {
      beginAt = state.filterDates.beginAt
      endAt = state.filterDates.endAt
    }
    const response = await fetch(`${process.env.SERVER_URL}/project/${state.activeProject._id}/${wbs_unit}/cells?begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`)
    if (response.ok) {
      const { cells } = await response.json();
      dispatch({
        type: "LOADED_CELLS",
        data: { cells, wbs_code: wbs_unit }
      })
    }
  }

  const loadSteps = async (wbs_cell, dateString) => {
    let beginAt;
    let endAt;
    if (dateString) {
      const [month, year] = dateString.split("/")
      const date = new Date(year, month - 1);
      beginAt = date;
      endAt = date;
    } else {
      beginAt = state.filterDates.beginAt
      endAt = state.filterDates.endAt
    }

    const response = await fetch(`${process.env.SERVER_URL}/project/${state.activeProject._id}/${wbs_cell}/steps?begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`)
    if (response.ok) {
      const { steps } = await response.json();
      dispatch({
        type: "LOADED_STEPS",
        data: { steps, wbs_code: wbs_cell }
      })
    }
  }

  const loadSubsteps = async (wbs_step, dateString) => {
    let beginAt;
    let endAt;
    if (dateString) {
      const [month, year] = dateString.split("/")
      const date = new Date(year, month - 1);
      beginAt = date;
      endAt = date;
    } else {
      beginAt = state.filterDates.beginAt
      endAt = state.filterDates.endAt
    }

    const response = await fetch(`${process.env.SERVER_URL}/project/${state.activeProject._id}/${wbs_step}/substeps?begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`)
    if (response.ok) {
      const { substeps } = await response.json();
      dispatch({
        type: "LOADED_SUBSTEPS",
        data: { substeps, wbs_code: wbs_step }
      })
    }
  }

  const loadServices = async (wbs_substep, dateString) => {
    let beginAt;
    let endAt;
    if (dateString) {
      const [month, year] = dateString.split("/")
      const date = new Date(year, month - 1);
      beginAt = date;
      endAt = date;
    } else {
      beginAt = state.filterDates.beginAt
      endAt = state.filterDates.endAt
    }

    const response = await fetch(`${process.env.SERVER_URL}/project/${state.activeProject._id}/${wbs_substep}/services?begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`)
    if (response.ok) {
      const { services } = await response.json();
      dispatch({
        type: "LOADED_SERVICES",
        data: { services, wbs_code: wbs_substep }
      })
    }
  }

  const selectDetail = (item, type) => dispatch({
    type: "SELECTED_DETAIL",
    data: { item, type }
  })

  const checkQueue = async (company_id, type, building_name, email) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)

    const response = await fetch(`${process.env.SERVER_URL}/project/checkUpdateQueue?company_id=${company_id}&type=${type}&building_name=${building_name}&email=${email}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)

    return response
  }

  const listCompanies = async () => {
    const { setIsLoading } = useDashboardUIActions();

    setIsLoading(true)

    const response = await fetch(`${process.env.SERVER_URL}/project/listCompanies`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    setIsLoading(false)
    return response
  }

  const createCompany = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/project/createCompany`, {
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const createBuilding = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/project/createBuilding`, {
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const getResources = async (id, page, order, pageSize, searchString) => {

    const response = await fetch(`${process.env.SERVER_URL}/project/getResources?id=${id}&page=${page}&order=${order}&pageSize=${Number(pageSize)}&searchString=${searchString}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const getContracts = async (type, id) => {

    const response = await fetch(`${process.env.SERVER_URL}/project/getContracts?type=${type}&id=${id}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const getCurve = async (id) => {

    const response = await fetch(`${process.env.SERVER_URL}/project/getCurve?building_wbs=${id}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const getCreditors = async (type, id, page, dateString, limitDate = false, toPay) => {

    let beginAt;
    let endAt;
    if (dateString) {
      const [month, year] = dateString.split("/")
      const date = new Date(year, month - 1);
      beginAt = date;
      endAt = date;
    } else {
      beginAt = state.filterDates.beginAt
      endAt = state.filterDates.endAt
    }
    if (!limitDate) {
      beginAt = new Date(1900, 0, 1);
      endAt = new Date(2500, 11, 12);
    }
    const response = await fetch(`${process.env.SERVER_URL}/project/getCreditor?type=${type}&id=${id}&page=${page}&toPay=${toPay}&begin_at=${beginAt.toISOString()}&end_at=${endAt.toISOString()}`, {

      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const getAllCreditors = async (wbs, search = '') => {
    const response = await fetch(`${process.env.SERVER_URL}/project/getAllCreditors?wbs=${wbs}&search=${search}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const getResourceEAP = async (resource) => {
    const { setIsLoading } = useDashboardUIActions();
    const {
      wbs_code_id_unit: unit,
      wbs_code_id_cell: cell,
      wbs_code_id_step: step,
      wbs_code_id_substep: substep,
      wbs_code_id_service: service,
      id,
      building_id,
      building_wbs
    } = resource
    setIsLoading(true)

    let url = `/project/getResourcesEAP?building_id=${building_id}&id=${id}&building_wbs=${building_wbs}`

    if (unit !== undefined) {
      url = `/project/getResourcesEAP?unit=${unit}&cell=${cell}&step=${step}&substep=${substep}&service=${service}&building_id=${building_id}&id=${id}`
    }

    const response = await fetch(`${process.env.SERVER_URL}${url}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    dispatch({
      type: "UPDATE_RESOURCE_EAP",
      data: response
    })

    setIsLoading(false)
  }

  const updateExtra = async (id, newValue, description) => {
    const { setIsLoading } = useDashboardUIActions();
    const value = typeof newValue === 'string'
      ? +newValue.replace(',', '.')
      : Number(newValue || 0);
    if (isNaN(value)) return
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/project/updateExtraValue`, {
      method: "POST",
      body: JSON.stringify({ id, newValue: value, description })
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const updateStock = async (id, newValue, description) => {
    const { setIsLoading } = useDashboardUIActions();
    const value = typeof newValue === 'string'
      ? +newValue.replace(',', '.')
      : Number(newValue || 0);
    if (isNaN(value)) return
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/project/updateStock`, {
      method: "POST",
      body: JSON.stringify({ id, newStockValue: value, description })
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getAppropriation = async (type, id, name = '') => {
    const response = await fetch(`${process.env.SERVER_URL}/project/getAppropriationItems?type=${type}&id=${id}&name=${name}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const updateAdvancement = async (name, newValue, description, to, from, building_id) => {
    const { setIsLoading } = useDashboardUIActions();
    const value = typeof newValue === 'string'
      ? +newValue.replace(',', '.')
      : Number(newValue || 0);
    if (isNaN(value)) return
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/project/updateAdvancement`, {
      method: "POST",
      body: JSON.stringify({ value, name, description, to, from, building_id })
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getAdvancements = async (name = '', building_id) => {
    const response = await fetch(`${process.env.SERVER_URL}/project/getAdvancements?name=${name}&building_id=${building_id}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })

    return response
  }

  const getWbsPath = async (wbs, building_wbs) => {
    // console.log(wbs, building_wbs)
    // return
    const response = await fetch(`${process.env.SERVER_URL}/project/getWbsPath?building_wbs=${building_wbs}&wbs=${wbs}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    return response
  }

  const selectXlsxData = async (data) => {
    dispatch({
      type: "SELECT_XLSX_DATA",
      data,
    });
  };


  return {
    list,
    selectProject,
    changeDates,
    loadUnits,
    loadCells,
    loadSteps,
    loadSubsteps,
    loadServices,
    selectDetail,
    getFollowUps,
    checkQueue,
    listCompanies,
    createCompany,
    createBuilding,
    getResources,
    getCreditors,
    getAllCreditors,
    getCurve,
    updateCurveID,
    getResourceEAP,
    getContracts,
    updateExtra,
    updateStock,
    getAppropriation,
    updateAdvancement,
    getAdvancements,
    getWbsPath,
    updateCubBased,
    updateProjectLive,
    selectXlsxData
  };
};
