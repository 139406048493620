import React, { useState, useEffect } from "react";
import { useBimActions, useBimContext } from "@contexts/bim";

const fields = [
  { label: 'Nome do Projeto', field: 'name', type: 'text' },
  { label: 'Cliente', field: 'client', type: 'text' },
  { label: 'Descrição', field: 'description', type: 'text' },
  { label: 'Data de Ínicio', field: 'startDate', type: 'date' },
  { label: 'Data de Fim', field: 'endDate', type: 'date' },
  { label: 'Status', field: 'status', type: 'select', options: ['Em Andamento', 'Concluído', 'Cancelado'] },
]

const DefaultRow = ({ item, edit, value, options }) => {
  const { field, label, type } = item
  if (type === 'select') {
    return <div>
      <label>{label}</label>
      <select onChange={edit} name={field} value={value}>
        <option disabled value=''>Selecione uma opção</option>
        {options?.map(i => {
          return <option key={i} value={i}>{i}</option>
        })}
      </select>
    </div>
  }
  return <div>
    <label>{label}</label>
    <input onChange={edit} placeholder={label} name={field} value={value} type={type} />
  </div>
}

const defaultProject = {
  _id: '',
  name: '',
  client: '',
  description: '',
  startDate: '',
  endDate: '',
  status: ''
}

const Project = () => {
  const [projectInfo, setProjectInfo] = useState(defaultProject)
  const [projects, setProjects] = useState([])
  const { updateProject, getChecklistSteps, getDeliverablesSteps, updateDeliverablesItem } = useBimActions();
  const { currentProject } = useBimContext();
  const [steps, setSteps] = useState({
    checklist: [],
    deliverables: []
  })

  const handleProjectEdit = (event) => {
    event.preventDefault();

    setProjectInfo(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const handleSend = async () => {
    const res = await updateProject(projectInfo)
  }

  const getSteps = async () => {
    const checklist = await getChecklistSteps(currentProject._id)
    const deliverables = await getDeliverablesSteps(currentProject._id)
    setSteps({
      checklist: checklist.res,
      deliverables: deliverables.res
    })

  }

  useEffect(() => {
    setProjectInfo(currentProject)
    if (currentProject._id !== "") {
      getSteps()
    }
  }, [currentProject])

  const reset = () => {
    setProjectInfo(defaultProject)
    setSteps({
      deliverables: [],
      checklist: []
    })
  }

  const handleDatechange = (e) => {
    const { name, value } = e.target;
    const [id, field] = name.split('-');
    const newSteps = steps.deliverables.map(i => {
      if (i._id === id) {
        return { ...i, [field]: value };
      }
      return i;
    });
    setSteps(prev => ({ ...prev, deliverables: newSteps }));
  }

  const updateStep = async (e) => {
    const step = steps.deliverables.filter(i => i._id === e.target.name)[0]
    await updateDeliverablesItem(step)
  }

  return <div className="costs">
    <h1>Cadastro de Projetos</h1>
    <button className="button button-accent" onClick={reset}>Novo projeto</button>
    <div className="grid columns-3-tablet gap-8">
      {fields.map(i => {
        return <DefaultRow key={i.field} item={i} options={i.options} value={projectInfo[i.field]} edit={handleProjectEdit} />
      })}
      <button className="button button-accent" onClick={handleSend}>{projects?.filter(i => i.name === projectInfo.name).length > 0 ? 'Atualizar Projeto' : "Enviar Projeto"}</button>
    </div>
    <h2>Entregáveis</h2>
    {steps.deliverables.map((i) => {
      return <div key={i._id} className="grid gap-8 columns-4-tablet align-center">
        <div>
          <p className="accordion-label">Etapa</p>
          <p className="accordion-value">{i.name}</p>
        </div>
        <div>
          <p className="accordion-label">Data de Início</p>
          <input type="date" value={i.startDate} name={`${i._id}-startDate`} onChange={handleDatechange} />
        </div>
        <div>
          <p className="accordion-label">Data de fim</p>
          <input type="date" value={i.endDate} name={`${i._id}-endDate`} onChange={handleDatechange} />
        </div>
        <button className="button button-accent small justify-self-center" name={i._id} onClick={updateStep}>Atualizar</button>
      </div>
    })}
    {/* <h2>Checklist</h2>
    {steps.checklist.map(i => {
      return <div key={i._id}>
        <p>{i.name}</p>
      </div>
    })} */}
  </div>
}

export default Project;
